<template>
  <div>
    <h2 style="padding-top:20px">企业信息</h2>
  
    <Form ref="formInline" :model="formInline" :rules="ruleInline"  label-position="right" :label-width="120" style="width:35%;margin:auto;padding-top:15px">
        <FormItem label="账号：" prop="username" ref="username" >
          <i-input type="text" v-bind:value="formInline.username"  :autofocus="true" :disabled="true">
                <Icon type="ios-person-outline" slot="prepend"></Icon>
          </i-input>
        </FormItem>
        <FormItem label="密码：" prop="password" style="padding-top:20px;padding-bottom:10px" v-if="companyid ==null">
            <i-input type="password" v-model="formInline.password" password placeholder="输入密码" :disabled="onlyview">
            </i-input>
        </FormItem>
         <FormItem label="密码确认：" prop="repassword" style="padding-top:10px;padding-bottom:10px" v-if="companyid ==null">
            <i-input type="password" v-model="formInline.repassword" placeholder="再次输入密码" password :disabled="onlyview">
            </i-input>
        </FormItem>
        <FormItem label="公司名称：" prop="companyname" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.companyname" placeholder="建议公司全称" :disabled="onlyview">
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <!--
        <Row :gutter="10" style="padding-top:10px;padding-bottom:10px">
          <i-col span="18">
            <FormItem label="公司编码：" prop="companycode" ref="companycode" >
                <i-input type="text" v-model="formInline.companycode" placeholder="建议公司名称拼音首字母大写">
                    <Icon type="ios-keypad-outline" slot="prepend"></Icon>
                </i-input>
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem :label-width="0">
              <Button type="warning" v-if="false" @click="checkcompany">检测</Button>
              <p style="color:blue" v-if="companycodeCanUsed == 0">需具备唯一性</p> 
              <p style="color:green" v-if="companycodeCanUsed == 1">√ 公司编码可用</p>  
              <p style="color:red" v-if="companycodeCanUsed == 2">× 公司编码已存在</p>  
            </FormItem>
          </i-col>
        </Row>
        -->
        <FormItem label="法人姓名：" prop="legalperson" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.legalperson" maxlength="20" show-word-limit :disabled="onlyview">
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>     
        <FormItem label="法人电话：" prop="legalphonenumber" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.legalphonenumber"  maxlength="20" show-word-limit :disabled="onlyview">
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem label="联系人名称：" prop="contact" style="padding-top:10px;padding-bottom:10px" :disabled="onlyview">
            <i-input type="text" v-model="formInline.contact" maxlength="20" show-word-limit :disabled="onlyview">
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>                   
        <FormItem label="联系人电话：" prop="phonenumber" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.phonenumber" maxlength="20" show-word-limit :disabled="onlyview">
                <Icon type="ios-phone-portrait" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem label="Email地址：" prop="emailaddr" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.emailaddr" placeholder="邮箱地址" :disabled="onlyview">
                <Icon type="ios-mail-outline" slot="prepend"></Icon>
            </i-input>
        </FormItem>        
        <FormItem label="社会信用代码：" prop="societycode" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.societycode" placeholder="社会信用代码" :disabled="onlyview">
                <Icon type="ios-medal-outline" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem prop="yzm" style="padding-top:10px;padding-bottom:10px">
          <Row type="flex" align="middle" justify="center">
            <i-col span="8">
              <i-input type="text" v-model="formInline.yzm" placeholder="验证码" :disabled="onlyview">
                  <Icon type="md-aperture" />
              </i-input>
            </i-col>
            <i-col span="1">  </i-col>
            <i-col span="8" ><yzm :identifyCode="identifyCode" v-on:onrefresh="refreshcode"></yzm></i-col>
          </Row>
        </FormItem>
        <FormItem style="padding-top:10px;padding-bottom:10px">
          <div style="display: inline-flex;">
            <Button type="primary"  @click="handleSubmit('formInline')" style="width:100%" >{{modifytext}}</Button>
            <Button type="warning"   @click="handleAbort('formInline')" style="width:100%;margin-left: 15px;" :disabled="onlyview == true">放弃修改</Button>
          </div>
        </FormItem>
    </Form>
  </div>
</template>
<script>
import SIdentify from '@/components/SIdentify.vue' //* *引入验证码组件**
export default {
  name: 'CompanyInfo',
  props:[
    "companyid"
  ],
  data () {
    const validateyzm = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请输入验证码'))
      } else if (value != this.identifyCode) {
        return callback(new Error('验证码输入错误'))
      } else {
        callback()
      }
    }
    const validatepwd = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请输入密码'))
      } else if (value != this.formInline.password) {
        return callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      onlyview:true,
      modifytext:'点击修改',
      formInline: {
        id:null,
        username: '',
        password: '',
        repassword: '',
        companyname: '',
        companycode: '',
        phonenumber: '',
        emailaddr:'',
        societycode: '',
        legalperson:'',
        legalphonenumber:'',
        contact:'',
        yzm: ''
      },
      identifyCodes: '1234567890',
      identifyCode: '12354',
      accountCanUsed:0,//1--can be used   2--can not be used
      companycodeCanUsed:0,//1--can be used   2--can not be used
      submmitDisabled:true, 
      ruleInline: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { pattern: /^[A-Z][A-Z]{3,15}$/, message: '企业简称全拼,必须大写4-16位', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
        ],
        repassword: [
          { validator: validatepwd, required: true, trigger: 'blur' }
        ],
        companyname: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { type: 'string', min: 4, message: '公司名称不能低于4位', trigger: 'blur' }
        ],
        /*
        companycode: [
          { required: true, message: '请输入公司编码', trigger: 'blur' },
          { pattern: /^[A-Z0-9]{4,15}$/, message: '4-16位大写英文字母和数字组合', trigger: 'blur' }
        ],*/
        legalperson: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' }
        ],
        legalphonenumber: [
          { required: true, message: '请输入法人电话', trigger: 'blur' },
          { type: 'string', min: 7, message: '法人电话不能低于7位', trigger: 'blur' }
        ],     
        contact: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],   
        phonenumber: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          { type: 'string', min: 8, message: '电话号码不能低于7位', trigger: 'blur' }
        ],
        emailaddr: [
          { required: true, message: '请输入Email', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]{2,4})$/, message: '请输入合法Email', trigger: 'blur' }
          
        ],
        yzm: [
          { validator: validateyzm, trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    'yzm': SIdentify
  },
  mounted () {
    this.refreshcode()
    console.log(this.companyid)
    this.getCompanyInfo()
  },
  watch:{
    'formInline.username':{
      deep:true,
      handler: function(newV, oldV) {
        if(this.accountCanUsed !=0){
          this.accountCanUsed =0
        }
      }
    },
    'formInline.companycode': {
      deep:true,
      handler: function(newV, oldV) {
        if(this.companycodeCanUsed !=0){
          this.companycodeCanUsed =0
        }
      }      
    },
    'companyid': function (v){
      console.log('companyid='+v)
    }
  },
  watch:{
    onlyview:function (v){
          if(v){
            this.modifytext = '点击修改'
          }
          else{
            this.modifytext = '提交修改'
          }
      }
  },
  methods: {
    handleAbort (name) {
      if(this.onlyview == false){
        this.onlyview = true
        this.modifytext = '点击修改'
        return
      }
    },
    handleSubmit (name) {
      if(this.onlyview){
        this.onlyview = false
        this.modifytext = '提交修改'
        return
      }

      this.$refs[name].validate((valid) => {
        if (valid) {
            this.$axios({
            method:'post',
            url:"/api/adminuser/update",
            data:this.formInline,
            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
            transformRequest: function(obj) {
                var str = [];
                for(var p in obj){
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
                return str.join("&");
            }
          }).then(function(res){
            if(res.data.resultCode ==0){
              this.$Message.success({content:'修改成功', duration:3, closable:true})
              this.onlyview = true
            }
            else{
              this.$Message.error({content:res.data.msg, duration:3})
            }
          }.bind(this));
        } else {
          this.$Message.error('请输入正确的内容')
          this.refreshcode()
        }
      })
    },
    getCompanyInfo(){
      let id= this.companyid
      if(id ==null){
        id = this._self.$root.$store.state.LogonInfo.companyid
      }
      this.formInline.id = id
      this.$axios({
        method:'post',
        url:"/api/adminuser/users/getcompanyinfo",
        data:{'id':id},
        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function(obj) {
            var str = [];
            for(var p in obj){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
            return str.join("&");
        }
      }).then(function(res){
        console.log(res.data)
        if(res.data.resultCode ==0){
          console.log(this.formInline)
          console.log(res.data.data)
          this.formInline.companyname = res.data.data.companyname
          this.formInline.username = res.data.data.username
          this.formInline.companycode = res.data.data.companycode
          this.formInline.phonenumber = res.data.data.phonenumber
          this.formInline.emailaddr = res.data.data.emailaddr
          this.formInline.societycode = res.data.data.societycode
          this.formInline.legalperson = res.data.data.legalperson
          this.formInline.legalphonenumber = res.data.data.legalphonenumber
          this.formInline.contact = res.data.data.contact
          console.log(this.formInline)
        }
        else{
          this.$Message.error('读取信息失败')
        }
      }.bind(this));
    },
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
      console.log(this.identifyCode)
    },
    refreshcode: function () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    }
  }
}
</script>

<style>
    .center {
        position: fixed;
        top: 50%;
        left: 50%;
        text-align: center;
        background-color: #ffffcc;
        width:70%;
        height: 70%;
        -webkit-transform: translateX(-50%) translateY(-50%);
    }
</style>
