<template>
    <div >
        <companyinfo :companyid="companyid" v-if="companyid !=null"/>        
    </div>
</template>

<script>
import CompanyInfo from '@/views/admin/CompanyInfo'    
export default {
    name:'ViewCompanyDetail',
    data () {
        return {
            companyid:null
        }
    },
    mounted (){
        console.log(this.$route.params)
        this.companyid = this.$route.params.companyid
    },
    methods: {
    },
    components:{
        companyinfo:CompanyInfo
    }
}
</script>